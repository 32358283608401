<template>
    <div class="contianer p-md-5 p-3">
        <PageLoader :isLoading="isLoading" />
        <DirectoryListingHeader title="Directory Listing Information" />
        <router-link
            class="text-primary"
            :to="{
                name: 'directory_category_listing',
                params: {
                    categorySlug: directory.service_category.slug,
                },
            }"
            v-if="directory.service_category"
            >Back to {{ directory.service_category.name }} listings</router-link
        >
        <div class="directory-content" v-if="directory">
            <div class="title">{{ directory.business_name }}</div>
            <div class="image-container" style="max-width: 400px">
                <ImageItem
                    :src="imgsrc + directory.business_photo"
                    :alt="directory.business_name"
                    class="img-fluid w-100"
                />
            </div>
            <div class="description">{{ directory.business_description }}</div>
            <div class="description" v-if="directory.id">
                <strong>Service Category:</strong> <br />
                <span style="font-size: .9em">{{
                    directory.service_category_other || directory.service_category.name
                }}</span>
            </div>

            <div class="links" v-if="directory.email || directory.phone">
                <div class="link-header">
                    Contact Details
                </div>
                <div class="contact-item" v-if="directory.email">Email: {{ directory.email }}</div>
                <div class="contact-item" v-if="directory.phone">Phone: {{ directory.phone }}</div>
            </div>
            <div class="links" v-if="directory.facebook_link || directory.linkedin_link">
                <div class="link-header">
                    Links
                </div>
                <DirectoryLinkItem title="Website" :link="directory.website" />
                <DirectoryLinkItem title="Facebook" :link="directory.facebook_link" />
                <DirectoryLinkItem title="LinkedIn" :link="directory.linkedin_link" />
            </div>
            <div class="posted" v-if="directory.id">
                Posted on {{ new Date(directory.created_at).toDateString() }}
            </div>
            <div class="posted" v-if="directory.id">By {{ directory.name }}</div>
            <div v-if="!isLoading && !directory.id">Directory Not Found</div>
        </div>
    </div>
</template>

<script>
import DirectoryListingHeader from "./DirectoryListingHeader.vue"
import DirectoryLinkItem from "./DirectoryLinkItem.vue"
import scrollTop from "../utils/scrollTop"
import PageLoader from "../Loader.vue"
import ImageItem from "../utils/ImageItem.vue"
import request from "../../apis/request"

export default {
    async mounted() {
        scrollTop()
        this.isLoading = true
        try {
            await this.fetchDirectory()
        } catch (err) {
            console.log("Could not fetch directory")
        }
        this.isLoading = false
    },
    components: {
        DirectoryListingHeader,
        DirectoryLinkItem,
        PageLoader,
        ImageItem,
    },
    data() {
        return {
            isLoading: false,
            directory: {},
            imgsrc: "/public",
        }
    },
    methods: {
        async fetchDirectory() {
            this.isLoading = true
            try {
                const res = await request.get(`/directories/${this.$route.params.id}`)
                const directory = res.data.result
                if (directory) {
                    this.directory = directory
                }
            } catch (err) {
                console.log("Error in fetching directory information")
            }
            this.isLoading = false
        },
    },
}
</script>

<style lang="scss" scoped>
.directory-content {
    margin: 20px 0;
    margin-top: 30px;
    border-left: 5px solid gold;
    padding: 20px;
    background: rgb(255, 252, 232);

    .title {
        font-weight: bold;
        font-size: 1.3em;
    }
    .description {
        margin: 20px 0;
        margin-bottom: 30px;
    }
    .posted {
        color: #555;
        font-size: 0.8em;
    }
}

.links {
    margin: 10px 0;
    margin-bottom: 20px;
    .link-header {
        font-weight: bold;
        margin-bottom: 10px;
    }
}

.contact-item {
    font-size: 0.85em;
}
</style>
