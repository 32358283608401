<template>
    <div class="link-item" v-if="link">
        <div class="link-title">{{ title }}:</div>
        <a :href="link" class="link-url text-primary" target="_blank">
            {{ link }}
        </a>
    </div>
</template>

<script>
export default {
    props: {
        link: {
            type: String,
            defualt: "",
        },
        title: {
            type: String,
            defualt: "",
        },
    },
}
</script>

<style lang="scss" scoped>
.link-item {
    font-size: 0.8em;
}
</style>
